// import Cookies from 'js-cookie';
export function loginAuth() {
    const CORPID = "ww265c7058b417d2ec";
    const REDIRECT_URI = encodeURIComponent(
        `${process.env.REACT_APP_DOMAIN}/auth`
    );
    const AGENTID = process.env.REACT_APP_AGENTID; // 生产：1000027；测试：1000036
    const SCOPE = "snsapi_userinfo"; // snsapi_base：静默授权，可获取成员的基础信息； snsapi_userinfo：静默授权，可获取成员的详细信息，但不包含手机、邮箱； snsapi_privateinfo：手动授权，可获取成员的详细信息，包含手机、邮箱。
    // const wechatAuthUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${CORPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE}&agentid=${AGENTID}&state=#wechat_redirect`;
    // if (Cookies.get('token')) { // 已经登录直接跳转
    //   window.location.replace('/main')
    // } else { // 未登录跳转企业微信授权页面
    //   window.location.replace(wechatAuthUrl)
    // }

    window.location.replace(
        new String(window.location.pathname).indexOf("zz") !== -1
            ? "/zzmain"
            : "/main"
    );
}

export function percent2fixed(value) {
    return ((value || 0) * 100).toFixed(2) + "%";
}

export function getQueryString(url) {
    url = url == null ? window.location.href : url;
    const search = url.substring(url.lastIndexOf('?') + 1);
    const obj = {};
    const reg = /([^&=]+)=([^&=#]*)/g;
    search.replace(reg, (rs, $1, $2) => {
      const name = decodeURIComponent($1);
      let val = decodeURIComponent($2);
      val = String(val);
      obj[name] = val;
      return rs;
    });
    return obj;
  }
