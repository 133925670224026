import React from 'react'
import style from './index.module.scss'
export default function IconContainer(props) {
  return (
    <div className={style.icon} style={props.style || {}}>
      <Icon type={props.type}></Icon>
    </div>
  )
}
const Icon = function(props) {
  if (props.type === 'up') {
    return <svg t="1577691074069" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5438" width="1em" height="1em" fill="currentColor" aria-hidden="true">
      <path d="M860.553846 748.307692H163.446154c-19.692308 0-33.476923-25.6-17.723077-43.323077l340.676923-417.476923c11.815385-15.753846 37.415385-15.753846 49.230769 0l344.615385 417.476923c13.784615 17.723077 1.969231 43.323077-19.692308 43.323077z" p-id="5439"></path>
    </svg>
  } else if (props.type === 'down') {
    return <svg t="1577692422074" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1726" width="1em" height="1em" fill="currentColor" aria-hidden="true">
      <path d="M163.446154 275.692308h697.107692c19.692308 0 33.476923 25.6 17.723077 43.323077L537.6 736.492308c-11.815385 15.753846-37.415385 15.753846-49.230769 0L143.753846 319.015385c-13.784615-17.723077-1.969231-43.323077 19.692308-43.323077z" p-id="1727"></path>
    </svg>
  } else if (props.type === 'arrow-up') {
    return <svg t="1577693166779" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7840" width="1em" height="1em" fill="currentColor" aria-hidden="true">
      <path d="M877.254 402.746l-320-320c-24.992-24.994-65.514-24.994-90.508 0l-320 320c-24.994 24.994-24.994 65.516 0 90.51 24.994 24.996 65.516 24.996 90.51 0L448 282.51 448 896c0 35.346 28.654 64 64 64 35.346 0 64-28.654 64-64L576 282.51l210.746 210.746C799.242 505.752 815.622 512 832 512s32.758-6.248 45.254-18.746C902.248 468.26 902.248 427.74 877.254 402.746z" p-id="7841"></path>
    </svg>
  } else if (props.type === 'calendar') {
    return <svg viewBox="64 64 896 896" focusable="false" data-icon="calendar" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path></svg>
  } else {
    return null
  }
}