import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import style from "./index.module.scss";
import headLogo from "../../assets/head-logo-mobile.png";
import locationIcon from "../../assets/location.png";
import rankIcon1 from "../../assets/rank1.png";
import rankIcon2 from "../../assets/rank2.png";
import rankIcon3 from "../../assets/rank3.png";
import rankIcon01 from "../../assets/rank01.png";
import rankIcon02 from "../../assets/rank02.png";
import rankIcon03 from "../../assets/rank03.png";
import moment from "moment";
import { screenInfo, listinfo, supplyinfo, afterSale } from "../../service";

import Analycis from "../../components/Analycis";
import Proportion from "../../components/Proportion";
import Icon from "../../components/Icon";
import { percent2fixed, getQueryString } from "../../utils";

import { DatePicker, Picker } from "antd-mobile";

class MobileMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionData: [
                {
                    value: "0",
                    label: "概览",
                    auth: 'main'
                },
                {
                    value: "1",
                    label: "站点分析（日）",
                    auth: 'kpi'
                },
                {
                    value: "2",
                    label: "优化师分析（日）",
                    auth: 'kpi'
                },
                // {
                //   value: "3",
                //   label: "优化师 TOP 5"
                // },
                {
                    value: "3",
                    label: "事业部利润监控",
                    auth: 'main'
                },
                {
                    value: "4",
                    label: "供应链（月）",
                    auth: 'supplyChain'
                },
                {
                    value: "5",
                    label: "上网口径供应链成本占比（月）",
                    auth: 'supplyChain'
                },
                {
                    value: "6",
                    label: "月退款情况",
                    auth: 'supplyChain'
                },
                {
                    value: "7",
                    label: "缺货情况",
                    auth: 'supplyChain'
                },
                {
                    value: "8",
                    label: "缺货SPU数量（TOP20）",
                    auth: 'supplyChain'
                },

                {
                    value: "9",
                    label: "产品类型退款率",
                    auth: 'afterSale'
                },
                {
                    value: "10",
                    label: "产品类型拒付率",
                    auth: 'afterSale'
                },
                {
                    value: "11",
                    label: "各事业部退款率",
                    auth: 'afterSale'
                },
                {
                    value: "12",
                    label: "各事业部拒付率",
                    auth: 'afterSale'
                },
                {
                    value: "13",
                    label: "各售后原因退款率",
                    auth: 'afterSale'
                },
                {
                    value: "14",
                    label: "各售后原因拒付率",
                    auth: 'afterSale'
                },
                {
                    value: "15",
                    label: "上月退款金额前十商品",
                    auth: 'afterSale'
                },
                // {
                //     value: "7",
                //     label: "商品销量TOP20 (月）",
                // },

                {
                    value: "16",
                    label: "全球销售分布",
                    auth: 'main'
                },
            ], // 段落数据

            date: moment().format("YYYY-MM-DD"),
            updateTime: "",
            currentAnchor: "0", // 页面滚动锚点

            // 原首页数据
            showRing: false,
            refreshInterval: null,
            countryFocusInterval: null,
            myChart: null,
            dayTotalUSD: 0, // 日成交额
            dayTotalRMB: 0, //
            profitRate: 0,
            monthTotalUSD: 0, // 月累计成交额
            monthTotalNum: 0, // 月累计销售数量
            dayRatio: 0, // 日环比
            scatterData: [],
            channelData: [
                // 渠道数据
                {
                    grand_total_usd: 0,
                    completion_rate: 0,
                    target_completion_rate: 0,
                },
                {
                    grand_total_usd: 0,
                    completion_rate: 0,
                    target_completion_rate: 0,
                },
                {
                    grand_total_usd: 0,
                    completion_rate: 0,
                    target_completion_rate: 0,
                },
            ],
            brandTop: [], // 站点top5
            optimizerTop: [], // 优化师top5
            dispatchers: [],
            teams: [],
            reasons: [],
            types: [],
            spus_afterSale: [],

            // 站点、优化师分析
            rankIcon: [rankIcon1, rankIcon2, rankIcon3],
            rankIcon0: [rankIcon01, rankIcon02, rankIcon03],
            brandList: [], // 站点top
            optimizerList: [], // 优化师top
            groupList: [],
            // 供应链分析
            totalRefund: 0,
            supply_chain_cost_rate: 0,
            supply_chain_cost_rate_ratio: 0,
            month_out_stock_rate: 0,
            month_out_stock_rate_ratio: 0,
            dispathcer_out_rate: 0,
            delivery_received_rate: 0,
            received_second_rate: 0,
            received_second_rate_ratio: 0,
            received_rate: 0,
            received_rate_ratio: 0,
            buying_totals: [],
            fulfilled: [],
            stocks: [],
            suppliers: [],
            hot_items: [],
            refunds: [],
            orders: [], //订单监控
            costs: [],
            lasts: [],
            spus: [],
            list_month_tables: [],
        };
    }

    componentDidMount() {
        if (getQueryString() && getQueryString().bigScreenPermission) {
            const pageOptions = this.state.sectionData
            const newPageOptions = pageOptions.filter(item => getQueryString().bigScreenPermission.indexOf(item.auth) > -1)
            this.setState({
                sectionData: newPageOptions
            })
            console.log('newPageOptions', newPageOptions)
        }
        var myChart = window.echarts.init(
            document.getElementById("mapContainer")
        );

        myChart.setOption(
            // 初始化echarts配置
            {
                tooltip: {
                    alwaysShowContent: true, // 不自动隐藏
                    enterable: true, // 鼠标可移入
                    confine: true,
                    // padding: [0, 0, 0, 20],
                    position: function (point, params, dom, rect, size) {
                        dom.style.transform = "translateZ(0)";
                        return [
                            point[0] + rect.width / 2,
                            point[1] - size.contentSize[1] - 20,
                        ];
                    },
                    backgroundColor: "transparent",
                    formatter: function (params, ticket, callback) {
                        // 自定义tooltips
                        return `
            <div class="tooltips-container">
              <div class="tooltips-head">
                <img src="${locationIcon}"/><span>${params.data.data.country
                            }</span></div>
              <div class="tooltips-content">
                <div class="tooltips-item">
                  <span class="item-name">月成交</span>
                  <span class="item-value">$${params.data.data.grand_total_usd &&
                            params.data.data.grand_total_usd.toFixed(2)
                            }</span>
                </div>
                <div class="tooltips-item">
                  <span class="item-name">月成交量</span>
                  <span class="item-value">${params.data.data.order_num}</span>
                </div>
                <div class="tooltips-item">
                  <span class="item-name">付费</span>
                  <span class="item-value">$${params.data.data.paid_grand_total_usd.toFixed(
                                2
                            )}</span>
                </div>
                <div class="tooltips-item">
                  <span class="item-name">非付费</span>
                  <span class="item-value">$${params.data.data.not_paid_grand_total_usd.toFixed(
                                2
                            )}</span>
                </div>
              </div>
            </div>
          `;
                    },
                },
                geo: {
                    map: "world",
                    label: {
                        // 鼠标悬浮不显示国家名
                        emphasis: {
                            show: false,
                        },
                    },
                    itemStyle: {
                        // 设置国家填充色和边界色
                        normal: {
                            areaColor: "#4b4b4b",
                            borderWidth: 0.4,
                            borderColor: "#2d2d30",
                        },
                        emphasis: {
                            areaColor: "#4b4b4b",
                            borderWidth: 0.4,
                            borderColor: "#2d2d30",
                        },
                    },
                    layoutCenter: ["50%", "50%"], // 地图中心位置，layoutCenter和layoutSize确保地图比例不变
                    layoutSize: (1200 * window.innerWidth) / 750, // 地图宽度
                },
                series: [
                    {
                        type: "scatter", // 散点图
                        coordinateSystem: "geo", // 地理坐标系
                        symbolSize: function (val) {
                            let tempSize = Math.sqrt(val[2]) / 20;
                            if (tempSize < 2) {
                                tempSize = 2;
                            } else if (tempSize > 30) {
                                tempSize = 30;
                            }
                            return tempSize;
                        },
                        data: [],
                        activeOpacity: 1,
                        itemStyle: {
                            color: "#ffc000", // 填充色
                            shadowColor: "rgba(255, 192, 0, 0.34)", // 阴影色
                            shadowBlur: 10, // 阴影宽度
                            emphasis: {
                                color: "#fff",
                                shadowColor: "rgba(255, 255, 255, 0.34)",
                            },
                        },
                    },
                ],
            },
            true
        );
        this.setState({
            myChart,
        });
        this.getDataInfo();
        this.refreshInterval = setInterval(() => {
            // 每分钟更新数据
            this.getDataInfo();
        }, 60000);
    }

    componentWillUnmount() {
        // 跳转其他页面时清空interval和timeout
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
        if (this.state.countryFocusInterval) {
            clearInterval(this.state.countryFocusInterval);
        }
    }

    getDataInfo() {
        this.getScreenInfo();
        this.getListInfo();
        this.getSupplyInfo();
        this.getAfterSale();
    }
    dayOfYear = (date) => {
        if (!date) return null;
        let [year, month, day] = date.split("-");
        let day1 = null;
        if (year == 2020) {
            //如果是2020年从9月1日算起
            day1 = new Date(year, 8, 1);
        } else {
            day1 = new Date(year, 0);
        }
        let day2 = new Date(year, month - 1, day);
        let nums = (day2 - day1) / (1000 * 60 * 60 * 24) + 1;
        return nums > 0 ? nums : 0;
    };
    dayOfMouth = (date) => {
        if (!date) return null;
        let [year, mouth, day] = date.split("-");
        return day;
    };
    getMonthLength = (date) => {
        if (!date) return 0;
        let d = new Date(date);
        // 将日期设置为下月一号
        d.setMonth(d.getMonth() + 1);
        d.setDate("1");
        // 获取本月最后一天
        d.setDate(d.getDate() - 1);
        return d.getDate();
    };
    getYearLength = (date) => {
        let [year] = date.split("-");
        return this.dayOfYear(year + "-12-31");
    };
    getMouthDateRate(date) {
        if (!date) return 0;

        let rate = +(this.dayOfMouth(date) / this.getMonthLength(date)).toFixed(
            4
        );
        return rate;
    }
    getYearDateRate(date) {
        if (!date) return 0;
        let rate = +(this.dayOfYear(date) / this.getYearLength(date)).toFixed(
            4
        );
        return rate;
    }
    async getScreenInfo() {
        this.setState({
            showRing: false,
        });
        let result = await screenInfo(this.state.date);
        const data = result.data;
        this.setState({
            showRing: true,
            updateTime: data.update_time,
            dayTotalUSD: data.day_grand_total_usd || 0,
            dayTotalRMB: data.day_grand_total_rmb || 0,
            profitRate: data.profit_rate,
            monthTotalUSD: data.month_grand_total_usd,
            monthTotalNum: data.month_goods_num,
            dayRatio: data.day_ring_ratio,
            channelData: data.channel_sale_vos,
            groupList:
                data.group_profits && data.group_profits.length > 0
                    ? data.group_profits.sort((a, b) => a.rank - b.rank)
                    : this.state.groupList, // 接口数据为空时不更新数据
            brandTop:
                data.brand_vos.length > 0
                    ? data.brand_vos
                    : this.state.brandTop, // 接口数据为空时不更新数据
            optimizerTop:
                data.optimizer_vos.length > 0
                    ? data.optimizer_vos
                    : this.state.optimizerTop,
            scatterData:
                data.scatter_data_vos.length > 0
                    ? data.scatter_data_vos.map((scatter) => ({
                        name: scatter.country_code,
                        value: [
                            scatter.longitude,
                            scatter.latitude,
                            scatter.grand_total_usd,
                        ],
                        data: { ...scatter },
                    }))
                    : this.state.scatterData,
        });
        var { myChart, countryFocusInterval } = this.state;
        myChart.setOption({
            // 设置echarts数据
            series: [
                {
                    data: this.state.scatterData,
                },
            ],
        });
        let showData = this.state.scatterData
            .sort((a, b) => {
                return a.value > b.value;
            })
            .map((item) => item.name)
            .slice(0, 10);
        let showIndex = 0;

        if (countryFocusInterval) {
            // 隐藏之前显示的
            myChart.dispatchAction({
                type: "downplay",
                seriesIndex: 0,
            });
            myChart.dispatchAction({
                type: "hideTip",
                seriesIndex: 0,
            });
            clearInterval(countryFocusInterval);
        }
        setTimeout(() => {
            // 前十国家循环显示tooltips
            myChart.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                name: showData[showIndex],
            });
            myChart.dispatchAction({
                type: "showTip",
                seriesIndex: 0,
                name: showData[showIndex],
            });
            showIndex = 1;
            let countryFocusInterval = setInterval(function () {
                // 隐藏之前显示的
                myChart.dispatchAction({
                    type: "downplay",
                    seriesIndex: 0,
                });
                myChart.dispatchAction({
                    type: "hideTip",
                    seriesIndex: 0,
                });
                // 显示tooltips
                myChart.dispatchAction({
                    type: "highlight",
                    seriesIndex: 0,
                    name: showData[showIndex],
                });
                myChart.dispatchAction({
                    type: "showTip",
                    seriesIndex: 0,
                    name: showData[showIndex],
                });
                if (showIndex < showData.length - 1) {
                    showIndex++;
                } else {
                    showIndex = 0;
                }
            }, 8000);
            this.setState({
                countryFocusInterval,
            });
        }, 400);
    }

    async getListInfo() {
        let result = await listinfo(this.state.date);
        const data = result.data;

        this.setState({
            updateTime: data.update_time,
            brandList: data.brand_vos,
            optimizerList: data.optimizer_vos,
            completeRateStandard: data.complete_rate_standard,
        });
    }

    async getSupplyInfo() {
        let result = await supplyinfo(this.state.date);
        let totalRefund = 0;
        const data = result.data;
        data.supply_refunds.forEach((item) => {
            totalRefund += item.refund_amount_usd;
        });
        let costs = data.month_supply || [];
        var list = [];
        costs.forEach((i) => {
            if (i.data && i.data.length > 0) {
                i.data = i.data.map((e) => ({
                    ...e,
                    goods_type: i.goods_type,
                }));
                // list.concat(...i.data);
                list = [...list, ...i.data];
            }
        });

        var list1 = (data.supply_refunds || []).map((item) => {
            const pay_m = new String(item.pay_month).replace(/\-/g, "/");
            const date = new Date(pay_m);
            const year = date.getFullYear();
            let mouth = date.getMonth() + 1;
            mouth = mouth >= 10 ? mouth : `0${mouth}`;

            item.name = item.pay_month
                ? new Date(pay_m).getMonth() + 1 + "月"
                : "往月";
            var target = (data.last_month_refunds || []).find(
                (i) => i.date == `${year}-${mouth}`
            );
            return { ...item, ...target };
        });

        let spus = data.month_spu_stocks || [];
        spus = spus.map((i) => {
            i.goods_type = i.type;
            i.stock_rate = i.spu_stock_rate_month * 100 || 0;
            i.stock_num = i.spu_stock_num_month || 0;
            i.order_num = (i.stock_num / i.spu_stock_rate_month).toFixed(0);
            return i;
        });

        this.setState({
            totalRefund,
            supply_chain_cost_rate: data.supply_chain_cost_rate,
            spu_stocks: data.spu_stocks,
            supply_chain_cost_rate_ratio: data.supply_chain_cost_rate_ratio,
            month_out_stock_rate: data.month_out_stock_rate,
            month_out_stock_rate_ratio: data.month_out_stock_rate_ratio,
            dispathcer_out_rate: data.dispathcer_out_rate,
            delivery_received_rate: data.delivery_received_rate,
            received_second_rate: data.received_second_rate,
            received_second_rate_ratio: data.received_second_rate_ratio,
            received_rate: data.received_rate,
            received_rate_ratio: data.received_rate_ratio,
            buying_totals: data.buying_totals || [],
            fulfilled: data.fulfilled,
            stocks: data.stocks,
            suppliers: data.suppliers,
            hot_items: data.hot_items || [],
            refunds: data.supply_refunds,
            orders: data.order_monitor, //订单监控
            costs: list,
            lasts: data.last_month_refunds,
            spus: spus,
            list_month_tables: list1,
        });
    }

    async getAfterSale() {
        let result = await afterSale({ date: this.state.date });
        const {
            application_condition_type,
            application_condition_reason,
            application_condition_team,
            application_condition_dispatcher,
            application_condition_spu,
        } = result.data;
        let dispatchers = this.fixedDispatcher(
            application_condition_dispatcher
        );
        let teams = this.fixedValues(application_condition_team);
        let reasons = this.fixedValues(application_condition_reason);
        let types = this.fixedValues(application_condition_type);
        debugger;
        this.setState({
            dispatchers,
            teams,
            reasons,
            types,
            spus_afterSale: application_condition_spu,
        });
    }

    fixedValues(data) {
        data = data || [];
        data.forEach((row) => {
            row.data = row.data || [];
            row.data.forEach((m_row, index) => {
                row[`refund_rate${m_row.month}`] = percent2fixed(m_row.refund_rate);
                row[`disputes_rate${m_row.month}`] = percent2fixed(
                    m_row.disputes_rate
                );

                row[`refund_rate${m_row.month}_num`] = m_row.refund_rate;
                row[`disputes_rate${m_row.month}_num`] = m_row.disputes_rate;
            });
        });
        return data;
    }
    fixedDispatcher(data) {
        data = data || [];
        let newData = [];
        data.forEach((row) => {
            let no = {
                type: "未发货",
            };
            let yes = {
                type: "已发货",
            };
            Object.keys(row).forEach((key) => {
                if (key.indexOf("refund_no") !== -1) {
                    no[key] = row[key];
                } else if (key.indexOf("refund_") !== -1) {
                    yes[key] = row[key];
                } else {
                    yes[key] = row[key];
                    no[key] = row[key];
                }
            });
            newData.push(no);
            newData.push(yes);
        });
        //交换位置来调整显示位置
        if (newData.length > 3) {
            const temp = JSON.stringify(newData[1]);
            newData[1] = JSON.parse(JSON.stringify(newData[2]));
            newData[2] = JSON.parse(temp);
        }
        return newData;
    }
    hideStr = (str) => {
        if (str.length <= 5) {
            return str[0] + '***'
        } else {
            let a = new Array(3).fill('*')
            return str.slice(0, 3) + a.join('') + str[str.length - 1]
        }
    }
    render() {
        // 用做数字滚动效果
        const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        const dayTotalUSD = this.state.dayTotalUSD.toFixed(2);

        let dayTotalUSDArray = [];
        for (let index = 0; index < dayTotalUSD.length; index++) {
            let temp = dayTotalUSD[index];
            dayTotalUSDArray.push(
                parseInt(temp) === temp ? parseInt(temp) : temp
            );
        }

        let mouth = new Date(this.state.date).getMonth() + 1;
        let before = mouth - 1;
        before = before < 1 ? before + 12 : before;
        let last_before = before - 1;
        last_before = last_before < 1 ? last_before + 12 : last_before;

        const { sectionData } = this.state

        return (
            <div className={style["screen-container"]}>
                <div className={style["screen-wrap"]} id="screenWrap">
                    <Scrollbars
                        ref="scrollbars"
                        onScroll={(event) => {
                            // 页面滚动段落选择自动更改
                            let offsetTops = [];
                            document
                                .querySelectorAll("[data-anchor]")
                                .forEach((ele, index) => {
                                    offsetTops[index] = {top: ele.offsetTop, value: ele.dataset.anchor};
                                });
                            for (let i = offsetTops.length - 1; i > -1; i--) {
                                if (i === offsetTops.length - 1) {
                                    if (
                                        event.target.scrollTop > offsetTops[i].top
                                    ) {
                                        if (this.state.currentAnchor !== offsetTops[i].value) {
                                            this.setState({
                                                currentAnchor: offsetTops[i].value,
                                            });
                                        }
                                        break;
                                    }
                                } else {
                                    if (
                                        event.target.scrollTop >=
                                        offsetTops[i].top &&
                                        event.target.scrollTop <
                                        offsetTops[i + 1].top
                                    ) {
                                        if (this.state.currentAnchor !== offsetTops[i].value) {
                                            this.setState({
                                                currentAnchor: offsetTops[i].value,
                                            });
                                        }
                                        break;
                                    }
                                }
                            }
                        }}
                    >
                        {sectionData.some(i => i.auth === 'main') && <div className={style["screen-content"]}>
                            {/* <div className={style["head-logo"]} data-anchor="0">
                                <img src={headLogo} alt="pearlgo" />
                            </div> */}

                            <div
                                className={`${style["total-number-container"]} heigh-light`}
                            >
                                <span className={style["total-number"]}>
                                    <span className={style["dollar-icon"]}>
                                        $
                                    </span>
                                    {/* <div>{this.state.dayTotalUSD.toFixed(2)}</div> */}
                                    <div
                                        className={style["slide-num-container"]}
                                    >
                                        {dayTotalUSDArray.map((usd, i) => (
                                            <div
                                                className={
                                                    style["num-container"]
                                                }
                                                key={i}
                                            >
                                                {typeof usd == "number" ? (
                                                    <div
                                                        className={
                                                            style["num-content"]
                                                        }
                                                        style={{
                                                            transform:
                                                                "translateY(-" +
                                                                usd +
                                                                "0%)",
                                                        }}
                                                    >
                                                        {numbers.map(
                                                            (num, j) => (
                                                                <div key={j}>
                                                                    {num}
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div>{usd}</div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <span className={style["rmb-icon"]}>
                                        ￥
                                    </span>
                                    <div className={style["rmb-total"]}>
                                        {this.state.dayTotalRMB &&
                                            this.state.dayTotalRMB.toFixed(2)}
                                    </div>
                                    <div className={style["day-roi"]}>
                                        <span className={style["roi-icon"]}>
                                            毛利率
                                        </span>
                                        <span style={{ marginLeft: "20px" }}>
                                            {this.state.profitRate &&
                                                (
                                                    this.state.profitRate * 100
                                                ).toFixed(2)}
                                            %
                                        </span>
                                    </div>
                                </span>
                            </div>

                            <div
                                className={`${style["head-info"]} ${style["right-info"]}`}
                            >
                                <div className={style["head-info-item"]}>
                                    <div>
                                        <h4
                                            className={
                                                this.state.dayRatio > 0
                                                    ? style["heigh-light"]
                                                    : ""
                                            }
                                        >
                                            {this.state.dayRatio &&
                                                this.state.dayRatio.toFixed(2)}
                                            %
                                            <Icon
                                                style={{
                                                    fontSize: 16 / 75 + "rem",
                                                    marginLeft: 8 / 75 + "rem",
                                                }}
                                                type={
                                                    this.state.dayRatio > 0
                                                        ? "up"
                                                        : "down"
                                                }
                                            />
                                        </h4>
                                        <p>日环比</p>
                                    </div>
                                </div>
                                <div className={style["head-info-item"]}>
                                    <div>
                                        <h4>
                                            {this.state.monthTotalUSD &&
                                                this.state.monthTotalUSD.toFixed(
                                                    2
                                                )}
                                        </h4>
                                        <p>月累计成交额</p>
                                    </div>
                                </div>
                                <div className={style["head-info-item"]}>
                                    <div>
                                        <h4>{this.state.monthTotalNum}</h4>
                                        <p>月累计销售数量</p>
                                    </div>
                                </div>
                            </div>

                            <div className={style["head-info"]}>
                                <div className={style["head-info-item"]}>
                                    <div>
                                        <h4>月累计成交额</h4>
                                        <p className={style["left-item"]}>
                                            <span className={style["label"]}>
                                                付费
                                            </span>
                                            <span>
                                                $
                                                {this.state.channelData[0] &&
                                                    this.state.channelData[0].grand_total_usd.toFixed(
                                                        2
                                                    )}
                                            </span>
                                        </p>
                                        <p className={style["left-item"]}>
                                            <span className={style["label"]}>
                                                非付
                                            </span>
                                            <span>
                                                $
                                                {this.state.channelData[1] &&
                                                    this.state.channelData[1].grand_total_usd.toFixed(
                                                        2
                                                    )}
                                            </span>
                                        </p>
                                        <p className={style["left-item"]}>
                                            <span className={style["label"]}>
                                                自然
                                            </span>
                                            <span>
                                                $
                                                {this.state.channelData[2] &&
                                                    this.state.channelData[2].grand_total_usd.toFixed(
                                                        2
                                                    )}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className={style["head-info-item"]}>
                                    <div>
                                        <h4>完成率</h4>
                                        <p className={style["left-item"]}>
                                            {this.state.channelData[0] &&
                                                this.state.channelData[0].completion_rate.toFixed(
                                                    2
                                                )}
                                            %
                                        </p>
                                        <p className={style["left-item"]}>
                                            {this.state.channelData[1] &&
                                                this.state.channelData[1].completion_rate.toFixed(
                                                    2
                                                )}
                                            %
                                        </p>
                                    </div>
                                </div>
                                <div className={style["head-info-item"]}>
                                    <div>
                                        <h4>时间进度</h4>
                                        <p className={style["left-item"]}>
                                            {this.state.channelData[0] &&
                                                this.state.channelData[0].target_completion_rate.toFixed(
                                                    2
                                                )}
                                            %
                                        </p>
                                        <p className={style["left-item"]}>
                                            {this.state.channelData[0] &&
                                                this.state.channelData[0].target_completion_rate.toFixed(
                                                    2
                                                )}
                                            %
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        <div className={style["screen-content"]}>
                            {sectionData.some(i => i.auth === 'kpi') && <div
                                className={style["rank-container"]}
                                data-anchor="1"
                            >
                                <h4 className={style["rank-title"]}>
                                    站点分析（日）
                                </h4>
                                <div className={style["rank-table-container"]}>
                                    <table className={style["rank-table"]}>
                                        <thead>
                                            <tr>
                                                <th>站点</th>
                                                <th>毛利</th>
                                                {/* <th>成交数</th> */}
                                                <th style={{ width: "1.7rem" }}>
                                                    月毛利率
                                                </th>
                                                <th>客单价</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.brandList.map(
                                                (brand, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <div
                                                                className={
                                                                    style[
                                                                    "rank-td"
                                                                    ]
                                                                }
                                                            >
                                                                {index >= 3 ? (
                                                                    <span
                                                                        className={
                                                                            style[
                                                                            "index-icon"
                                                                            ]
                                                                        }
                                                                    >
                                                                        {index +
                                                                            1}
                                                                    </span>
                                                                ) : (
                                                                    <img
                                                                        className={
                                                                            style[
                                                                            "index-icon"
                                                                            ]
                                                                        }
                                                                        src={
                                                                            this
                                                                                .state
                                                                                .rankIcon[
                                                                            index
                                                                            ]
                                                                        }
                                                                        alt="rank"
                                                                    />
                                                                )}
                                                                <span
                                                                    style={{
                                                                        flex: "1 1 auto",
                                                                        wordBreak:
                                                                            "break-all",
                                                                    }}
                                                                >
                                                                    {
                                                                        this.hideStr(brand.brand_name)
                                                                    }
                                                                </span>
                                                                <div
                                                                    style={{
                                                                        flex: "1 0 auto",
                                                                        textAlign:
                                                                            "right",
                                                                    }}
                                                                    className={
                                                                        brand.ranking_diff >
                                                                            0
                                                                            ? "heigh-light"
                                                                            : ""
                                                                    }
                                                                >
                                                                    <Icon
                                                                        style={{
                                                                            fontSize:
                                                                                16 /
                                                                                75 +
                                                                                "rem",
                                                                        }}
                                                                        type={
                                                                            brand.ranking_diff >
                                                                                0
                                                                                ? "up"
                                                                                : "down"
                                                                        }
                                                                    />
                                                                    <span>
                                                                        {brand.ranking_diff
                                                                            ? Math.abs(
                                                                                brand.ranking_diff
                                                                            )
                                                                            : 0}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                $
                                                                {brand.profit &&
                                                                    brand.profit.toFixed(
                                                                        2
                                                                    )}
                                                            </p>
                                                            <div
                                                                className={
                                                                    style[
                                                                    "small-font"
                                                                    ]
                                                                }
                                                            >
                                                                <div>
                                                                    月: $
                                                                    {brand.month_profit &&
                                                                        brand.month_profit.toFixed(
                                                                            2
                                                                        )}
                                                                </div>
                                                                <p
                                                                    style={{
                                                                        width: "2rem",
                                                                    }}
                                                                >
                                                                    环比:
                                                                    <span
                                                                    // className={
                                                                    //     brand.grand_total_usd_ring_ratio >
                                                                    //     0
                                                                    //         ? "heigh-light"
                                                                    //         : ""
                                                                    // }
                                                                    >
                                                                        {brand.profit_ring_ratio &&
                                                                            brand.profit_ring_ratio.toFixed(
                                                                                2
                                                                            )}
                                                                        %
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </td>
                                                        {/* <td>
                        <p>{brand.goods_num && brand.goods_num}</p>
                        <p className={style['small-font']}>
                          环比:<span className={brand.goods_num_ring_ratio > 0 ? 'heigh-light' : ''}>{brand.goods_num_ring_ratio && brand.goods_num_ring_ratio.toFixed(2)}%</span>
                        </p>
                      </td> */}
                                                        <td>
                                                            <p
                                                                className={
                                                                    brand.profit_rate >
                                                                        11 &&
                                                                        brand.profit_rate !==
                                                                        0
                                                                        ? "heigh-light"
                                                                        : ""
                                                                }
                                                            >
                                                                {(brand.profit_rate &&
                                                                    brand.profit_rate.toFixed(
                                                                        2
                                                                    )) ||
                                                                    0.0}
                                                                %
                                                            </p>
                                                            <div
                                                                className={
                                                                    style[
                                                                    "small-font"
                                                                    ]
                                                                }
                                                            >
                                                                ROI:
                                                                {brand.roi &&
                                                                    brand.roi.toFixed(
                                                                        2
                                                                    )}
                                                                <div>
                                                                    CPA:
                                                                    {brand.cpa &&
                                                                        brand.cpa.toFixed(
                                                                            2
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            $
                                                            {brand.unit_price &&
                                                                brand.unit_price.toFixed(
                                                                    2
                                                                )}
                                                            <p
                                                                className={
                                                                    style[
                                                                    "small-font"
                                                                    ]
                                                                }
                                                                style={{
                                                                    width: "1.42rem",
                                                                }}
                                                            >
                                                                连带:
                                                                {brand.associated_rate &&
                                                                    brand.associated_rate.toFixed(
                                                                        2
                                                                    )}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                            {sectionData.some(i => i.auth === 'kpi') && <div
                                className={style["rank-container"]}
                                data-anchor="2"
                            >
                                <h4 className={style["rank-title"]}>
                                    优化师分析（日）
                                </h4>
                                <div className={style["rank-table-container"]}>
                                    <table className={style["rank-table"]}>
                                        <thead>
                                            <tr>
                                                <th>优化师</th>
                                                <th>毛利</th>
                                                <th>毛利进度</th>
                                                <th>客单价</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.optimizerList.map(
                                                (optimizer, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <div
                                                                className={
                                                                    style[
                                                                    "rank-td"
                                                                    ]
                                                                }
                                                            >
                                                                {index >= 3 ? (
                                                                    <span
                                                                        className={
                                                                            style[
                                                                            "index-icon"
                                                                            ]
                                                                        }
                                                                    >
                                                                        {index +
                                                                            1}
                                                                    </span>
                                                                ) : (
                                                                    <img
                                                                        className={
                                                                            style[
                                                                            "index-icon"
                                                                            ]
                                                                        }
                                                                        src={
                                                                            this
                                                                                .state
                                                                                .rankIcon0[
                                                                            index
                                                                            ]
                                                                        }
                                                                        alt="rank"
                                                                    />
                                                                )}
                                                                <span
                                                                    style={{
                                                                        flex: 1,
                                                                    }}
                                                                >
                                                                    {
                                                                        optimizer.name
                                                                    }
                                                                </span>
                                                                <div
                                                                    className={
                                                                        optimizer.ranking_diff >
                                                                            0
                                                                            ? "heigh-light"
                                                                            : ""
                                                                    }
                                                                >
                                                                    <Icon
                                                                        style={{
                                                                            fontSize:
                                                                                16 /
                                                                                75 +
                                                                                "rem",
                                                                        }}
                                                                        type={
                                                                            optimizer.ranking_diff >
                                                                                0
                                                                                ? "up"
                                                                                : "down"
                                                                        }
                                                                    />
                                                                    <span>
                                                                        {Math.abs(
                                                                            optimizer.ranking_diff
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                $
                                                                {optimizer.day_profit &&
                                                                    optimizer.day_profit.toFixed(
                                                                        2
                                                                    )}
                                                            </p>
                                                            <div
                                                                className={
                                                                    style[
                                                                    "small-font"
                                                                    ]
                                                                }
                                                            >
                                                                月: $
                                                                {optimizer.profit &&
                                                                    optimizer.profit.toFixed(
                                                                        2
                                                                    )}
                                                                <div
                                                                    style={{
                                                                        width: "2rem",
                                                                    }}
                                                                >
                                                                    环比:
                                                                    <span
                                                                    // className={
                                                                    //     optimizer.day_usd_ring_rate >
                                                                    //     0
                                                                    //         ? "heigh-light"
                                                                    //         : ""
                                                                    // }
                                                                    >
                                                                        {optimizer.day_profit_ring_rate &&
                                                                            optimizer.day_profit_ring_rate.toFixed(
                                                                                2
                                                                            )}
                                                                        %
                                                                    </span>
                                                                </div>
                                                                {/* <div>
                                                                    ROI:
                                                                    {optimizer.roi &&
                                                                        optimizer.roi.toFixed(
                                                                            2
                                                                        )}
                                                                </div> */}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p
                                                                className={
                                                                    optimizer.profit_jindu >=
                                                                        1
                                                                        ? "heigh-light"
                                                                        : ""
                                                                }
                                                            >
                                                                {optimizer.profit_jindu &&
                                                                    (
                                                                        optimizer.profit_jindu *
                                                                        100
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                %
                                                            </p>
                                                            <div
                                                                className={
                                                                    style[
                                                                    "small-font"
                                                                    ]
                                                                }
                                                            >
                                                                <div>
                                                                    月利率:
                                                                    <span
                                                                        className={
                                                                            optimizer.profit_rate >
                                                                                11 &&
                                                                                optimizer.profit_rate !==
                                                                                0
                                                                                ? "heigh-light"
                                                                                : ""
                                                                        }
                                                                    >
                                                                        {optimizer.profit_rate &&
                                                                            optimizer.profit_rate.toFixed(
                                                                                2
                                                                            )}
                                                                        %
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={
                                                                    style[
                                                                    "small-font"
                                                                    ]
                                                                }
                                                            >
                                                                ROI：
                                                                <span>
                                                                    {optimizer.roi &&
                                                                        optimizer.roi.toFixed(
                                                                            2
                                                                        )}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            $
                                                            {optimizer.unit_price &&
                                                                optimizer.unit_price.toFixed(
                                                                    2
                                                                )}
                                                            <p
                                                                className={
                                                                    style[
                                                                    "small-font"
                                                                    ]
                                                                }
                                                            >
                                                                转化:
                                                                {optimizer.conversion_rate &&
                                                                    optimizer.conversion_rate.toFixed(
                                                                        2
                                                                    )}
                                                                %
                                                            </p>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                            <div
                                className={style["table-container"]}
                                style={{ display: "none" }}
                            >
                                <h3>优化师 TOP 5</h3>
                                <div className={style["optimization-table"]}>
                                    {this.state.optimizerTop.map(
                                        (optimizer, index) => (
                                            <div
                                                className={
                                                    style["optimization-item"]
                                                }
                                                key={index}
                                            >
                                                <div
                                                    className={
                                                        style[
                                                        "optimization-index"
                                                        ]
                                                    }
                                                >
                                                    {index + 1}
                                                </div>
                                                <div
                                                    className={
                                                        style[
                                                        "optimization-name"
                                                        ]
                                                    }
                                                >
                                                    {optimizer.name}
                                                </div>
                                                <div
                                                    className={
                                                        style[
                                                        "optimization-progress"
                                                        ]
                                                    }
                                                >
                                                    <p>
                                                        完成率：
                                                        {optimizer.completion_rate &&
                                                            optimizer.completion_rate.toFixed(
                                                                2
                                                            )}
                                                        %&nbsp;$
                                                        {optimizer.month_grand_total_usd &&
                                                            optimizer.month_grand_total_usd.toFixed(
                                                                2
                                                            )}
                                                        &nbsp;/&nbsp;$
                                                        {optimizer.month_target_grand_total_usd &&
                                                            optimizer.month_target_grand_total_usd.toFixed(
                                                                2
                                                            )}
                                                    </p>
                                                    <div
                                                        className={
                                                            style[
                                                            "progress-container"
                                                            ]
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                style[
                                                                "progress"
                                                                ]
                                                            }
                                                            style={{
                                                                width:
                                                                    (optimizer.completion_rate >=
                                                                        100
                                                                        ? 100
                                                                        : optimizer.completion_rate) +
                                                                    "%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        style[
                                                        "optimization-roi"
                                                        ]
                                                    }
                                                >
                                                    <p>ROI</p>
                                                    <p>
                                                        {optimizer.roi &&
                                                            optimizer.roi.toFixed(
                                                                2
                                                            )}
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        style[
                                                        "optimization-today"
                                                        ]
                                                    }
                                                >
                                                    <p>今日</p>
                                                    <p>
                                                        $
                                                        {optimizer.day_grand_total_usd &&
                                                            optimizer.day_grand_total_usd.toFixed(
                                                                2
                                                            )}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                        {sectionData.some(i => i.auth === 'main') && <div
                            className={style["rank-container"]}
                            style={{
                                paddingTop: "0.4rem",
                                paddingLeft: "0.26667rem",
                                paddingRight: "0.26667rem",
                                paddingBottom: "0.53333rem",
                            }}
                            data-anchor="3"
                        >
                            <h4 className={style["rank-title"]}>
                                事业部利润监控
                            </h4>
                            <div className={style["rank-table-container"]}>
                                <table className={style["rank-table"]}>
                                    <thead>
                                        <tr>
                                            <th>事业部</th>
                                            <th>业绩达成</th>
                                            {/* <th>月利润</th> */}
                                            <th>完成进度</th>
                                            <th>月人均利润</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.groupList.map(
                                            (group, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <div
                                                            className={
                                                                style["rank-td"]
                                                            }
                                                        >
                                                            {index >= 3 ? (
                                                                <span
                                                                    className={
                                                                        style[
                                                                        "index-icon"
                                                                        ]
                                                                    }
                                                                >
                                                                    {index + 1}
                                                                </span>
                                                            ) : (
                                                                <img
                                                                    className={
                                                                        style[
                                                                        "index-icon"
                                                                        ]
                                                                    }
                                                                    src={
                                                                        this
                                                                            .state
                                                                            .rankIcon0[
                                                                        index
                                                                        ]
                                                                    }
                                                                    alt="rank"
                                                                />
                                                            )}
                                                            <span
                                                                style={{
                                                                    flex: 1,
                                                                }}
                                                            >
                                                                {
                                                                    group.sale_group
                                                                }
                                                            </span>
                                                            <div
                                                                className={
                                                                    group.diff_rank >
                                                                        0
                                                                        ? "heigh-light"
                                                                        : ""
                                                                }
                                                            >
                                                                <Icon
                                                                    style={{
                                                                        fontSize:
                                                                            16 /
                                                                            75 +
                                                                            "rem",
                                                                    }}
                                                                    type={
                                                                        group.diff_rank >
                                                                            0
                                                                            ? "up"
                                                                            : "down"
                                                                    }
                                                                />
                                                                <span>
                                                                    {Math.abs(
                                                                        group.diff_rank
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            月成交额：$
                                                            {group.month_grand_total_usd_roi_new &&
                                                                group.month_grand_total_usd_roi_new.toFixed(
                                                                    2
                                                                )}
                                                        </div>
                                                        <div>
                                                            月利润： $
                                                            {group.month_profit &&
                                                                group.month_profit.toFixed(
                                                                    2
                                                                )}
                                                        </div>
                                                    </td>
                                                    {/* <td></td> */}
                                                    <td>
                                                        <div
                                                            className={
                                                                group.month_profit_completed_rate >=
                                                                    this.getMouthDateRate(
                                                                        this.state
                                                                            .date
                                                                    )
                                                                    ? "heigh-light"
                                                                    : ""
                                                            }
                                                        >
                                                            月：
                                                            {group.month_profit_completed_rate &&
                                                                (
                                                                    group.month_profit_completed_rate *
                                                                    100
                                                                ).toFixed(2)}
                                                            %
                                                        </div>
                                                        <div
                                                            className={
                                                                group.year_profit_completed_rate >=
                                                                    this.getYearDateRate(
                                                                        this.state
                                                                            .date
                                                                    )
                                                                    ? "heigh-light"
                                                                    : ""
                                                            }
                                                        >
                                                            年：
                                                            {group.year_profit_completed_rate &&
                                                                (
                                                                    group.year_profit_completed_rate *
                                                                    100
                                                                ).toFixed(2)}
                                                            %
                                                        </div>
                                                    </td>

                                                    <td>
                                                        $
                                                        {group.month_numbers_avg_profit
                                                            ? group.month_numbers_avg_profit.toFixed(
                                                                2
                                                            )
                                                            : "--"}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>}

                        <div className={style["rank-container"]}>
                            {sectionData.some(i => i.auth === 'supplyChain') && <div>
                                <div
                                    style={{
                                        paddingTop: "0.4rem",
                                        paddingLeft: "0.26667rem",
                                        paddingRight: "0.26667rem",
                                        paddingBottom: "0.53333rem",
                                    }}
                                    data-anchor="4"
                                >
                                    <h4 className={style["rank-title"]}>
                                        订单监控（月）
                                    </h4>
                                    <div className={style["rank-table-container"]}>
                                        <table className={style["rank-table"]}>
                                            <thead>
                                                <tr>
                                                    <th>18天妥投率</th>
                                                    <th>5/7天配货率</th>
                                                    {/* <th>月利润</th> */}
                                                    <th>13天下方妥投率</th>
                                                    <th>3天承接率</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.orders.map(
                                                    (record, index) => (
                                                        <tr key={index}>
                                                            <td>{record.type}</td>
                                                            <td>
                                                                {percent2fixed(
                                                                    record.delivery_ok_rate_18_day
                                                                )}
                                                            </td>
                                                            <td>
                                                                {percent2fixed(
                                                                    record.dispatcher_rate_day
                                                                )}
                                                            </td>
                                                            <td>
                                                                {percent2fixed(
                                                                    record.cang_to_delivery_ok_rate_13_day
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className={style["rank-container"]}>
                                    <div className={style["rank-table-container"]}>
                                        <table className={style["rank-table"]}>
                                            <thead>
                                                <tr>
                                                    <th>类别</th>
                                                    <th style={{ lineHeight: 1.3 }}>
                                                        <div>支付到妥投</div>
                                                        <div>平均时效</div>
                                                    </th>
                                                    <th style={{ lineHeight: 1.3 }}>
                                                        <div>已配货</div>
                                                        <div>平均时效</div>
                                                    </th>
                                                    <th style={{ lineHeight: 1.3 }}>
                                                        <div>配货到下发</div>
                                                        <div>平均时效</div>
                                                    </th>
                                                    <th style={{ lineHeight: 1.3 }}>
                                                        <div>下发到上网</div>
                                                        <div>平均时效</div>
                                                    </th>
                                                    <th style={{ lineHeight: 1.3 }}>
                                                        <div>上网到妥投平均</div>
                                                        <div>时效</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.orders.map(
                                                    (record, index) => (
                                                        <tr key={index}>
                                                            <td>{record.type}</td>
                                                            <td
                                                                style={{
                                                                    color:
                                                                        record.pay_to_delivery_ok_avg_time >
                                                                            18
                                                                            ? "red"
                                                                            : "",
                                                                }}
                                                            >
                                                                {(
                                                                    record.pay_to_delivery_ok_avg_time ||
                                                                    0
                                                                ).toFixed(2)}
                                                                天
                                                            </td>
                                                            <td
                                                                style={{
                                                                    color:
                                                                        record.dispatcher_to_cang_date >
                                                                            (record.type ==
                                                                                "大货"
                                                                                ? 5
                                                                                : 7)
                                                                            ? "red"
                                                                            : "",
                                                                }}
                                                            >
                                                                {(
                                                                    record.dispatcher_to_cang_date ||
                                                                    0
                                                                ).toFixed(2)}
                                                                天
                                                            </td>
                                                            <td
                                                                style={{
                                                                    color:
                                                                        record.dispatcher_to_cang_avg_time >
                                                                            2
                                                                            ? "red"
                                                                            : "",
                                                                }}
                                                            >
                                                                {(
                                                                    record.dispatcher_to_cang_avg_time ||
                                                                    0
                                                                ).toFixed(2)}
                                                                天
                                                            </td>
                                                            <td
                                                                style={{
                                                                    color:
                                                                        record.cang_to_received_second_avg_time >
                                                                            1.5
                                                                            ? "red"
                                                                            : "",
                                                                }}
                                                            >
                                                                {(
                                                                    record.cang_to_received_second_avg_time ||
                                                                    0
                                                                ).toFixed(2)}
                                                                天
                                                            </td>
                                                            <td
                                                                style={{
                                                                    color:
                                                                        record.received_second_to_delivery_ok_avg_time >
                                                                            13
                                                                            ? "red"
                                                                            : "",
                                                                }}
                                                            >
                                                                {(
                                                                    record.received_second_to_delivery_ok_avg_time ||
                                                                    0
                                                                ).toFixed(2)}
                                                                天
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            <div
                                className={style["rank-container"]}
                                data-anchor="5"
                            >
                                <h4 className={style["rank-title"]}>
                                    上网口径供应链成本占比（月）
                                </h4>
                                <div className={style["rank-table-container"]}>
                                    <table className={style["rank-table"]}>
                                        <thead>
                                            <tr>
                                                <th>品类</th>
                                                <th>类型</th>
                                                <th>供应链成本占比</th>
                                                <th>商品成本占比</th>
                                                <th>物流成本占比</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.costs.map(
                                                (item, index) => (
                                                    <tr key={index}>
                                                        {index % 2 == 0 ? (
                                                            <td rowspan="2">
                                                                {
                                                                    item.goods_type
                                                                }
                                                            </td>
                                                        ) : null}
                                                        <td>{item.type}</td>
                                                        <td>
                                                            {percent2fixed(
                                                                item.supply_rate
                                                            )}
                                                        </td>
                                                        <td>
                                                            {percent2fixed(
                                                                item.buying_total_rate
                                                            )}
                                                        </td>
                                                        <td>
                                                            {percent2fixed(
                                                                item.ship_rate
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className={style["rank-container"]}>
                                <div
                                    style={{
                                        paddingTop: "0.4rem",
                                        paddingLeft: "0.26667rem",
                                        paddingRight: "0.26667rem",
                                        paddingBottom: "0.53333rem",
                                    }}
                                    data-anchor="6"
                                >
                                    <h4 className={style["rank-title"]}>
                                        <h3>{`3个月退款情况`}</h3>
                                    </h4>
                                    <div
                                        className={
                                            style["rank-table-container"]
                                        }
                                    >
                                        <table className={style["rank-table"]}>
                                            <thead>
                                                <tr>
                                                    <th>订单成交月份</th>
                                                    <th>退款金额</th>
                                                    <th>成交额</th>
                                                    <th>退款率</th>
                                                    <th>拒付率</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.list_month_tables.map(
                                                    (item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.name}</td>
                                                            <td>
                                                                {item.refund_amount_usd
                                                                    ? `$${item.refund_amount_usd}`
                                                                    : "--"}
                                                            </td>
                                                            <td>
                                                                {item.grand_total_usd
                                                                    ? `$${item.grand_total_usd}`
                                                                    : "--"}
                                                            </td>
                                                            <td>
                                                                {item.refund_rate
                                                                    ? (
                                                                        item.refund_rate ||
                                                                        0
                                                                    ).toFixed(
                                                                        2
                                                                    ) + "%"
                                                                    : "--"}
                                                            </td>
                                                            <td>
                                                                {item.jufu_rate
                                                                    ? (
                                                                        item.jufu_rate ||
                                                                        0
                                                                    ).toFixed(
                                                                        2
                                                                    ) + "%"
                                                                    : "--"}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div
                                className={style["rank-container"]}
                                data-anchor="7"
                            >
                                <Analycis state={this.state} />
                            </div>
                            <div
                                className={style["rank-container"]}
                                data-anchor="8"
                            >
                                <h4 className={style["rank-title"]}>
                                    缺货SPU数量（TOP20）
                                </h4>
                                <div className={style["rank-table-container"]}>
                                    <table
                                        className={`${style["rank-table"]} ${style["center-table"]}`}
                                    >
                                        <thead>
                                            <tr>
                                                <th>SPU ID</th>
                                                <th>SPU 图片</th>
                                                <th>缺货数量</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.spu_stocks || []).map(
                                                (item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.spu_id}</td>
                                                        <td>
                                                            <img
                                                                style={{
                                                                    width: "0.49333rem",
                                                                    height: "0.49333rem",
                                                                }}
                                                                src={
                                                                    item.spu_img
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            {item.stock_count}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            </div>}
                            {sectionData.some(i => i.auth === 'afterSale') && <div>                                      
                            <div
                                className={style["rank-container"]}
                                data-anchor="9"
                            >
                                <h4 className={style["rank-title"]}>
                                    产品类型退款率
                                </h4>
                                <div className={style["rank-table-container"]}>
                                    <table
                                        className={`${style["rank-table"]} ${style["center-table"]}`}
                                    >
                                        <thead>
                                            <tr>
                                                <th>产品类型</th>
                                                <th>{mouth}月</th>
                                                <th>{before}月</th>
                                                <th>{last_before}月</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.types || []).map(
                                                (item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.type}</td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`refund_rate${mouth}_num`] >
                                                                        0.02
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {item[`refund_rate${mouth}`]}
                                                        </td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`refund_rate${before}_num`] >
                                                                        0.06
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {item[`refund_rate${before}`]}
                                                        </td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`refund_rate${last_before}_num`] >
                                                                        0.9
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {item[`refund_rate${last_before}`]}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div
                                className={style["rank-container"]}
                                data-anchor="10"
                            >
                                <h4 className={style["rank-title"]}>
                                    产品类型拒付率
                                </h4>
                                <div className={style["rank-table-container"]}>
                                    <table
                                        className={`${style["rank-table"]} ${style["center-table"]}`}
                                    >
                                        <thead>
                                            <tr>
                                                <th>产品类型</th>
                                                <th>{mouth}月</th>
                                                <th>{before}月</th>
                                                <th>{last_before}月</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.types || []).map(
                                                (item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.type}</td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`disputes_rate${mouth}_num`] >
                                                                        0.15
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {
                                                                item[`disputes_rate${mouth}`]
                                                            }
                                                        </td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`disputes_rate${before}_num`] >
                                                                        0.03
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {
                                                                item[`disputes_rate${before}`]
                                                            }
                                                        </td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`disputes_rate${last_before}_num`] >
                                                                        0.05
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {
                                                                item[`disputes_rate${last_before}`]
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div
                                className={style["rank-container"]}
                                data-anchor="11"
                            >
                                <h4 className={style["rank-title"]}>
                                    事业部退款率
                                </h4>
                                <div className={style["rank-table-container"]}>
                                    <table
                                        className={`${style["rank-table"]} ${style["center-table"]}`}
                                    >
                                        <thead>
                                            <tr>
                                                <th>事业部</th>
                                                <th>{mouth}月</th>
                                                <th>{before}月</th>
                                                <th>{last_before}月</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.teams || []).map(
                                                (item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.type}</td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`refund_rate${mouth}_num`] >
                                                                        0.02
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {item[`refund_rate${mouth}`]}
                                                        </td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`refund_rate${before}_num`] >
                                                                        0.06
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {item[`refund_rate${before}`]}
                                                        </td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`refund_rate${last_before}_num`] >
                                                                        0.9
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {item[`refund_rate${last_before}`]}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div
                                className={style["rank-container"]}
                                data-anchor="12"
                            >
                                <h4 className={style["rank-title"]}>
                                    事业部拒付率
                                </h4>
                                <div className={style["rank-table-container"]}>
                                    <table
                                        className={`${style["rank-table"]} ${style["center-table"]}`}
                                    >
                                        <thead>
                                            <tr>
                                                <th>事业部</th>
                                                <th>{mouth}月</th>
                                                <th>{before}月</th>
                                                <th>{last_before}月</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.teams || []).map(
                                                (item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.type}</td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`disputes_rate${mouth}_num`] >
                                                                        0.15
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {
                                                                item[`disputes_rate${mouth}`]
                                                            }
                                                        </td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`disputes_rate${before}_num`] >
                                                                        0.03
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {
                                                                [`disputes_rate${before}`]
                                                            }
                                                        </td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`disputes_rate${last_before}_num`] >
                                                                        0.05
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {
                                                                item[`disputes_rate${last_before}`]
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div
                                className={style["rank-container"]}
                                data-anchor="13"
                            >
                                <h4 className={style["rank-title"]}>
                                    售后原因退款率
                                </h4>
                                <div className={style["rank-table-container"]}>
                                    <table
                                        className={`${style["rank-table"]} ${style["center-table"]}`}
                                    >
                                        <thead>
                                            <tr>
                                                <th>售后原因</th>
                                                <th>{mouth}月</th>
                                                <th>{before}月</th>
                                                <th>{last_before}月</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.reasons || []).map(
                                                (item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.type}</td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`refund_rate${mouth}_num`] >
                                                                        0.02
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {item[`refund_rate${mouth}`]}
                                                        </td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`refund_rate${before}_num`] >
                                                                        0.06
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {item[`refund_rate${before}`]}
                                                        </td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`refund_rate${last_before}_num`] >
                                                                        0.9
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {item[`refund_rate${last_before}`]}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div
                                className={style["rank-container"]}
                                data-anchor="14"
                            >
                                <h4 className={style["rank-title"]}>
                                    售后原因拒付率
                                </h4>
                                <div className={style["rank-table-container"]}>
                                    <table
                                        className={`${style["rank-table"]} ${style["center-table"]}`}
                                    >
                                        <thead>
                                            <tr>
                                                <th>售后原因</th>
                                                <th>{mouth}月</th>
                                                <th>{before}月</th>
                                                <th>{last_before}月</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.reasons || []).map(
                                                (item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.type}</td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`disputes_rate${mouth}_num`] >
                                                                        0.15
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {
                                                                item[`disputes_rate${mouth}`]
                                                            }
                                                        </td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`disputes_rate${before}_num`] >
                                                                        0.03
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {
                                                                item[`disputes_rate${before}`]
                                                            }
                                                        </td>
                                                        <td
                                                            style={{
                                                                color:
                                                                    item[`disputes_rate${last_before}_num`] >
                                                                        0.05
                                                                        ? "red"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            {
                                                                item[`disputes_rate${last_before}`]
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div
                                className={style["rank-container"]}
                                data-anchor="15"
                            >
                                <h4 className={style["rank-title"]}>
                                    上月退款金额前十商品
                                </h4>
                                <div className={style["rank-table-container"]}>
                                    <table
                                        className={`${style["rank-table"]} ${style["center-table"]}`}
                                    >
                                        <thead>
                                            <tr>
                                                <th>SPU</th>
                                                <th>SPU图片</th>
                                                <th>退款金额</th>
                                                <th>退款率</th>
                                                <th>拒付率</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(
                                                this.state.spus_afterSale || []
                                            ).map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.spu}</td>
                                                    <td>
                                                        <img
                                                            style={{
                                                                width: "0.49333rem",
                                                                height: "0.49333rem",
                                                            }}
                                                            src={item.image}
                                                        />
                                                    </td>
                                                    <td>
                                                        $
                                                        {item.refund_amount_usd}
                                                    </td>
                                                    <td>
                                                        {percent2fixed(
                                                            item.refund_rate
                                                        )}
                                                    </td>
                                                    <td>
                                                        {percent2fixed(
                                                            item.disputes_rate
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            </div>}  
                        </div>

                        {sectionData.some(i => i.auth === 'main') && <div
                            className={style["screen-content"]}
                            style={{ padding: 0 }}
                            data-anchor="16"
                        >
                            <div
                                className={style["map-container"]}
                                id="mapContainer"
                            ></div>
                        </div>}
                    </Scrollbars>
                </div>

                <div className={style["screen-bottom"]}>
                    <div className={style["screen-bottom-content"]}>
                        <div className={style["bottom-item"]}>
                            <DatePicker
                                mode="date"
                                value={new Date(this.state.date)}
                                onChange={(value) => {
                                    this.setState(
                                        {
                                            date: moment(value).format(
                                                "YYYY-MM-DD"
                                            ),
                                        },
                                        () => {
                                            this.getDataInfo();
                                        }
                                    );
                                }}
                            >
                                <div>
                                    <span>{this.state.date}</span>
                                    <Icon
                                        type="calendar"
                                        style={{ marginLeft: 4 }}
                                    ></Icon>
                                </div>
                            </DatePicker>
                        </div>
                        <div className={style["bottom-item"]}>
                            <Picker
                                data={this.state.sectionData}
                                value={[this.state.currentAnchor]}
                                cols={1}
                                onChange={(value) => {
                                    this.refs.scrollbars.scrollTop(
                                        document.querySelector(
                                            `[data-anchor="${value}"]`
                                        ).offsetTop
                                    );
                                }}
                            >
                                <div>
                                    <span>
                                        {
                                            (
                                                this.state.sectionData.filter(
                                                    (obj) =>
                                                        obj.value ===
                                                        this.state.currentAnchor
                                                )[0] || {
                                                    value: "9",
                                                    label: "全球销售分布",
                                                }
                                            ).label
                                        }
                                    </span>
                                    <Icon
                                        type="down"
                                        style={{ marginLeft: 4 }}
                                    ></Icon>
                                </div>
                            </Picker>
                        </div>
                        <div
                            className={style["bottom-item"]}
                            onClick={() => {
                                this.refs.scrollbars.scrollTop(0);
                            }}
                        >
                            <span>TOP&nbsp;&nbsp;</span>
                            <Icon type="arrow-up" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MobileMain;
