import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import style from "./index.module.scss";
import headLogo from "../../assets/head-logo-mobilezz.png";
import locationIcon from "../../assets/location.png";
import rankIcon1 from "../../assets/rank1.png";
import rankIcon2 from "../../assets/rank2.png";
import rankIcon3 from "../../assets/rank3.png";
import rankIcon01 from "../../assets/rank01.png";
import rankIcon02 from "../../assets/rank02.png";
import rankIcon03 from "../../assets/rank03.png";
import moment from "moment";
import { screenInfo, listinfo, supplyinfo } from "../../service";

import Analycis from "../../components/ZAnalycis";
import Proportion from "../../components/Proportion";
import Icon from "../../components/Icon";

import { DatePicker, Picker } from "antd-mobile";

class MobileMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionData: [
                {
                    value: "0",
                    label: "概览",
                },
                {
                    value: "1",
                    label: "站点分析（日）",
                },
                {
                    value: "2",
                    label: "优化师分析（日）",
                },
                {
                    value: "3",
                    label: "优化师 TOP 5",
                },
                {
                    value: "4",
                    label: "供应链（月）",
                },
                {
                    value: "5",
                    label: "月退款情况",
                },
                {
                    value: "6",
                    label: "缺货情况分析",
                },
                {
                    value: "7",
                    label: "商品销量TOP20 (月）",
                },
                {
                    value: "8",
                    label: "供应链成本分析 (月）",
                },
                {
                    value: "9",
                    label: "全球销售分布",
                },
            ], // 段落数据

            date: moment().format("YYYY-MM-DD"),
            updateTime: "",
            currentAnchor: "0", // 页面滚动锚点

            // 原首页数据
            showRing: false,
            refreshInterval: null,
            countryFocusInterval: null,
            myChart: null,
            dayTotalUSD: 0, // 日成交额
            dayTotalRMB: 0, //
            dayROI: 0,
            monthTotalUSD: 0, // 月累计成交额
            monthTotalNum: 0, // 月累计销售数量
            dayRatio: 0, // 日环比
            scatterData: [],
            channelData: [
                // 渠道数据
                {
                    grand_total_usd: 0,
                    completion_rate: 0,
                    target_completion_rate: 0,
                },
                {
                    grand_total_usd: 0,
                    completion_rate: 0,
                    target_completion_rate: 0,
                },
                {
                    grand_total_usd: 0,
                    completion_rate: 0,
                    target_completion_rate: 0,
                },
            ],
            brandTop: [], // 站点top5
            optimizerTop: [], // 优化师top5

            // 站点、优化师分析
            rankIcon: [rankIcon1, rankIcon2, rankIcon3],
            rankIcon0: [rankIcon01, rankIcon02, rankIcon03],
            brandList: [], // 站点top
            optimizerList: [], // 优化师top

            // 供应链分析
            totalRefund: 0,
            supply_chain_cost_rate: 0,
            supply_chain_cost_rate_ratio: 0,
            month_out_stock_rate: 0,
            month_out_stock_rate_ratio: 0,
            dispathcer_out_rate: 0,
            delivery_received_rate: 0,
            received_second_rate: 0,
            received_second_rate_ratio: 0,
            received_rate: 0,
            received_rate_ratio: 0,
            buying_totals: [],
            fulfilled: [],
            stocks: [],
            suppliers: [],
            hot_items: [],
            refunds: [],
        };
    }

    componentDidMount() {
        // var myChart = window.echarts.init(
        //     document.getElementById("mapContainer")
        // );
        // myChart.setOption(
        //     // 初始化echarts配置
        //     {
        //         tooltip: {
        //             alwaysShowContent: true, // 不自动隐藏
        //             enterable: true, // 鼠标可移入
        //             confine: true,
        //             // padding: [0, 0, 0, 20],
        //             position: function (point, params, dom, rect, size) {
        //                 return [
        //                     point[0] + rect.width / 2,
        //                     point[1] - size.contentSize[1] - 20,
        //                 ];
        //             },
        //             backgroundColor: "transparent",
        //             formatter: function (params, ticket, callback) {
        //                 // 自定义tooltips
        //                 return `
        //     <div class="tooltips-container">
        //       <div class="tooltips-head">
        //         <img src="${locationIcon}"/><span>${
        //                     params.data.data.country
        //                 }</span></div>
        //       <div class="tooltips-content">
        //         <div class="tooltips-item">
        //           <span class="item-name">月成交</span>
        //           <span class="item-value">$${
        //               params.data.data.grand_total_usd &&
        //               params.data.data.grand_total_usd.toFixed(2)
        //           }</span>
        //         </div>
        //         <div class="tooltips-item">
        //           <span class="item-name">月成交量</span>
        //           <span class="item-value">${params.data.data.order_num}</span>
        //         </div>
        //         <div class="tooltips-item">
        //           <span class="item-name">付费</span>
        //           <span class="item-value">$${params.data.data.paid_grand_total_usd.toFixed(
        //               2
        //           )}</span>
        //         </div>
        //         <div class="tooltips-item">
        //           <span class="item-name">非付费</span>
        //           <span class="item-value">$${params.data.data.not_paid_grand_total_usd.toFixed(
        //               2
        //           )}</span>
        //         </div>
        //       </div>
        //     </div>
        //   `;
        //             },
        //         },
        //         geo: {
        //             map: "world",
        //             label: {
        //                 // 鼠标悬浮不显示国家名
        //                 emphasis: {
        //                     show: false,
        //                 },
        //             },
        //             itemStyle: {
        //                 // 设置国家填充色和边界色
        //                 normal: {
        //                     areaColor: "#4b4b4b",
        //                     borderWidth: 0.4,
        //                     borderColor: "#2d2d30",
        //                 },
        //                 emphasis: {
        //                     areaColor: "#4b4b4b",
        //                     borderWidth: 0.4,
        //                     borderColor: "#2d2d30",
        //                 },
        //             },
        //             layoutCenter: ["50%", "50%"], // 地图中心位置，layoutCenter和layoutSize确保地图比例不变
        //             layoutSize: (1200 * window.innerWidth) / 750, // 地图宽度
        //         },
        //         series: [
        //             {
        //                 type: "scatter", // 散点图
        //                 coordinateSystem: "geo", // 地理坐标系
        //                 symbolSize: function (val) {
        //                     let tempSize = Math.sqrt(val[2]) / 20;
        //                     if (tempSize < 2) {
        //                         tempSize = 2;
        //                     } else if (tempSize > 30) {
        //                         tempSize = 30;
        //                     }
        //                     return tempSize;
        //                 },
        //                 data: [],
        //                 activeOpacity: 1,
        //                 itemStyle: {
        //                     color: "#ffc000", // 填充色
        //                     shadowColor: "rgba(255, 192, 0, 0.34)", // 阴影色
        //                     shadowBlur: 10, // 阴影宽度
        //                     emphasis: {
        //                         color: "#fff",
        //                         shadowColor: "rgba(255, 255, 255, 0.34)",
        //                     },
        //                 },
        //             },
        //         ],
        //     },
        //     true
        // );
        // this.setState({
        //     myChart,
        // });
        this.getDataInfo();
        this.refreshInterval = setInterval(() => {
            // 每分钟更新数据
            this.getDataInfo();
        }, 60000);
    }

    componentWillUnmount() {
        // 跳转其他页面时清空interval和timeout
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
        if (this.state.countryFocusInterval) {
            clearInterval(this.state.countryFocusInterval);
        }
    }

    getDataInfo() {
        this.getScreenInfo();
        this.getListInfo();
        this.getSupplyInfo();
    }

    async getScreenInfo() {
        this.setState({
            showRing: false,
        });
        let result = await screenInfo(this.state.date);
        const data = result.data;
        this.setState({
            showRing: true,
            updateTime: data.update_time,
            dayTotalUSD: data.day_grand_total_usd || 0,
            dayTotalRMB: data.day_grand_total_rmb || 0,
            dayROI: data.day_roi,
            monthTotalUSD: data.month_grand_total_usd,
            monthTotalNum: data.month_goods_num,
            dayRatio: data.day_ring_ratio,
            channelData: data.channel_sale_vos,
            brandTop:
                data.brand_vos.length > 0
                    ? data.brand_vos
                    : this.state.brandTop, // 接口数据为空时不更新数据
            optimizerTop:
                data.optimizer_vos.length > 0
                    ? data.optimizer_vos
                    : this.state.optimizerTop,
            scatterData:
                data.scatter_data_vos.length > 0
                    ? data.scatter_data_vos.map((scatter) => ({
                          name: scatter.country_code,
                          value: [
                              scatter.longitude,
                              scatter.latitude,
                              scatter.grand_total_usd,
                          ],
                          data: { ...scatter },
                      }))
                    : this.state.scatterData,
        });
        var { myChart, countryFocusInterval } = this.state;
        // myChart.setOption({
        //     // 设置echarts数据
        //     series: [
        //         {
        //             data: this.state.scatterData,
        //         },
        //     ],
        // });
        let showData = this.state.scatterData
            .sort((a, b) => {
                return a.value > b.value;
            })
            .map((item) => item.name)
            .slice(0, 10);
        let showIndex = 0;

        if (countryFocusInterval) {
            // 隐藏之前显示的
            // myChart.dispatchAction({
            //     type: "downplay",
            //     seriesIndex: 0,
            // });
            // myChart.dispatchAction({
            //     type: "hideTip",
            //     seriesIndex: 0,
            // });
            clearInterval(countryFocusInterval);
        }
        // setTimeout(() => {
        //     // 前十国家循环显示tooltips
        //     myChart.dispatchAction({
        //         type: "highlight",
        //         seriesIndex: 0,
        //         name: showData[showIndex],
        //     });
        //     myChart.dispatchAction({
        //         type: "showTip",
        //         seriesIndex: 0,
        //         name: showData[showIndex],
        //     });
        //     showIndex = 1;
        //     let countryFocusInterval = setInterval(function () {
        //         // 隐藏之前显示的
        //         myChart.dispatchAction({
        //             type: "downplay",
        //             seriesIndex: 0,
        //         });
        //         myChart.dispatchAction({
        //             type: "hideTip",
        //             seriesIndex: 0,
        //         });
        //         // 显示tooltips
        //         myChart.dispatchAction({
        //             type: "highlight",
        //             seriesIndex: 0,
        //             name: showData[showIndex],
        //         });
        //         myChart.dispatchAction({
        //             type: "showTip",
        //             seriesIndex: 0,
        //             name: showData[showIndex],
        //         });
        //         if (showIndex < showData.length - 1) {
        //             showIndex++;
        //         } else {
        //             showIndex = 0;
        //         }
        //     }, 8000);
        //     this.setState({
        //         countryFocusInterval,
        //     });
        // }, 400);
    }

    async getListInfo() {
        let result = await listinfo(this.state.date);
        const data = result.data;
        this.setState({
            updateTime: data.update_time,
            brandList: data.brand_vos,
            optimizerList: data.optimizer_vos,
        });
    }

    async getSupplyInfo() {
        let result = await supplyinfo(this.state.date);
        let totalRefund = 0;
        const data = result.data;
        data.refunds.forEach((item) => {
            totalRefund += item.refund_amount_usd;
        });
        this.setState({
            totalRefund,
            supply_chain_cost_rate: data.supply_chain_cost_rate,
            supply_chain_cost_rate_ratio: data.supply_chain_cost_rate_ratio,
            month_out_stock_rate: data.month_out_stock_rate,
            month_out_stock_rate_ratio: data.month_out_stock_rate_ratio,
            dispathcer_out_rate: data.dispathcer_out_rate,
            delivery_received_rate: data.delivery_received_rate,
            received_second_rate: data.received_second_rate,
            received_second_rate_ratio: data.received_second_rate_ratio,
            received_rate: data.received_rate,
            received_rate_ratio: data.received_rate_ratio,
            buying_totals: data.buying_totals,
            fulfilled: data.fulfilled,
            stocks: data.stocks,
            suppliers: data.suppliers,
            hot_items: data.hot_items,
            refunds: data.refunds,
        });
    }

    render() {
        // 用做数字滚动效果
        const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        const dayTotalUSD = this.state.dayTotalUSD.toFixed(2);

        let dayTotalUSDArray = [];
        for (let index = 0; index < dayTotalUSD.length; index++) {
            let temp = dayTotalUSD[index];
            dayTotalUSDArray.push(
                parseInt(temp) === temp ? parseInt(temp) : temp
            );
        }
        return (
            <div className={style["screen-container"]}>
                <div className={style["screen-wrap"]} id="screenWrap">
                    <Scrollbars
                        ref="scrollbars"
                        onScroll={(event) => {
                            // 页面滚动段落选择自动更改
                            let offsetTops = [];
                            document
                                .querySelectorAll("[data-anchor]")
                                .forEach((ele, index) => {
                                    offsetTops[index] = ele.offsetTop;
                                });
                            for (let i = offsetTops.length - 1; i > -1; i--) {
                                if (i === offsetTops.length - 1) {
                                    if (
                                        event.target.scrollTop > offsetTops[i]
                                    ) {
                                        if (this.state.currentAnchor !== i) {
                                            this.setState({
                                                currentAnchor: i.toString(),
                                            });
                                        }
                                        break;
                                    }
                                } else {
                                    if (
                                        event.target.scrollTop >=
                                            offsetTops[i] &&
                                        event.target.scrollTop <
                                            offsetTops[i + 1]
                                    ) {
                                        if (this.state.currentAnchor !== i) {
                                            this.setState({
                                                currentAnchor: i.toString(),
                                            });
                                        }
                                        break;
                                    }
                                }
                            }
                        }}
                    >
                        <div className={style["screen-content"]}>
                            <div className={style["head-logo"]} data-anchor="0">
                                <img src={headLogo} alt="pearlgo" />
                            </div>

                            <div
                                className={`${style["total-number-container"]} heigh-light`}
                            >
                                <span className={style["total-number"]}>
                                    <span className={style["dollar-icon"]}>
                                        $
                                    </span>
                                    {/* <div>{this.state.dayTotalUSD.toFixed(2)}</div> */}
                                    <div
                                        className={style["slide-num-container"]}
                                    >
                                        {dayTotalUSDArray.map((usd, i) => (
                                            <div
                                                className={
                                                    style["num-container"]
                                                }
                                                key={i}
                                            >
                                                {typeof usd == "number" ? (
                                                    <div
                                                        className={
                                                            style["num-content"]
                                                        }
                                                        style={{
                                                            transform:
                                                                "translateY(-" +
                                                                usd +
                                                                "0%)",
                                                        }}
                                                    >
                                                        {numbers.map(
                                                            (num, j) => (
                                                                <div key={j}>
                                                                    {num}
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div>{usd}</div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <span className={style["rmb-icon"]}>
                                        ￥
                                    </span>
                                    <div className={style["rmb-total"]}>
                                        {this.state.dayTotalRMB &&
                                            this.state.dayTotalRMB.toFixed(2)}
                                    </div>
                                    <div className={style["day-roi"]}>
                                        <span className={style["roi-icon"]}>
                                            ROI
                                        </span>
                                        {this.state.dayROI &&
                                            this.state.dayROI.toFixed(2)}
                                    </div>
                                </span>
                            </div>

                            <div
                                className={`${style["head-info"]} ${style["right-info"]}`}
                            >
                                <div className={style["head-info-item"]}>
                                    <div>
                                        <h4
                                            className={
                                                this.state.dayRatio > 0
                                                    ? style["heigh-light"]
                                                    : ""
                                            }
                                        >
                                            {this.state.dayRatio &&
                                                this.state.dayRatio.toFixed(2)}
                                            %
                                            <Icon
                                                style={{
                                                    fontSize: 16 / 75 + "rem",
                                                    marginLeft: 8 / 75 + "rem",
                                                }}
                                                type={
                                                    this.state.dayRatio > 0
                                                        ? "up"
                                                        : "down"
                                                }
                                            />
                                        </h4>
                                        <p>日环比</p>
                                    </div>
                                </div>
                                <div className={style["head-info-item"]}>
                                    <div>
                                        <h4>
                                            {this.state.monthTotalUSD &&
                                                this.state.monthTotalUSD.toFixed(
                                                    2
                                                )}
                                        </h4>
                                        <p>月累计成交额</p>
                                    </div>
                                </div>
                                <div className={style["head-info-item"]}>
                                    <div>
                                        <h4>{this.state.monthTotalNum}</h4>
                                        <p>月累计销售数量</p>
                                    </div>
                                </div>
                            </div>

                            <div className={style["head-info"]}>
                                <div className={style["head-info-item"]}>
                                    <div>
                                        <h4>月累计成交额</h4>
                                        <p className={style["left-item"]}>
                                            <span className={style["label"]}>
                                                付费
                                            </span>
                                            <span>
                                                $
                                                {this.state.channelData[0] &&
                                                    this.state.channelData[0].grand_total_usd.toFixed(
                                                        2
                                                    )}
                                            </span>
                                        </p>
                                        <p className={style["left-item"]}>
                                            <span className={style["label"]}>
                                                非付
                                            </span>
                                            <span>
                                                $
                                                {this.state.channelData[1] &&
                                                    this.state.channelData[1].grand_total_usd.toFixed(
                                                        2
                                                    )}
                                            </span>
                                        </p>
                                        <p className={style["left-item"]}>
                                            <span className={style["label"]}>
                                                自然
                                            </span>
                                            <span>
                                                $
                                                {this.state.channelData[2] &&
                                                    this.state.channelData[2].grand_total_usd.toFixed(
                                                        2
                                                    )}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className={style["head-info-item"]}>
                                    <div>
                                        <h4>完成率</h4>
                                        <p className={style["left-item"]}>
                                            {this.state.channelData[0] &&
                                                this.state.channelData[0].completion_rate.toFixed(
                                                    2
                                                )}
                                            %
                                        </p>
                                        <p className={style["left-item"]}>
                                            {this.state.channelData[1] &&
                                                this.state.channelData[1].completion_rate.toFixed(
                                                    2
                                                )}
                                            %
                                        </p>
                                    </div>
                                </div>
                                <div className={style["head-info-item"]}>
                                    <div>
                                        <h4>时间进度</h4>
                                        <p className={style["left-item"]}>
                                            {this.state.channelData[0] &&
                                                this.state.channelData[0].target_completion_rate.toFixed(
                                                    2
                                                )}
                                            %
                                        </p>
                                        <p className={style["left-item"]}>
                                            {this.state.channelData[0] &&
                                                this.state.channelData[0].target_completion_rate.toFixed(
                                                    2
                                                )}
                                            %
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={style["screen-content"]}>
                            <div
                                className={style["rank-container"]}
                                data-anchor="1"
                            >
                                <h4 className={style["rank-title"]}>
                                    站点分析（日）
                                </h4>
                                <div className={style["rank-table-container"]}>
                                    <table className={style["rank-table"]}>
                                        <thead>
                                            <tr>
                                                <th>站点</th>
                                                <th>成交额</th>
                                                {/* <th>成交数</th> */}
                                                <th>完成进度</th>
                                                <th>客单价</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.brandList.map(
                                                (brand, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <div
                                                                className={
                                                                    style[
                                                                        "rank-td"
                                                                    ]
                                                                }
                                                            >
                                                                {index >= 3 ? (
                                                                    <span
                                                                        className={
                                                                            style[
                                                                                "index-icon"
                                                                            ]
                                                                        }
                                                                    >
                                                                        {index +
                                                                            1}
                                                                    </span>
                                                                ) : (
                                                                    <img
                                                                        className={
                                                                            style[
                                                                                "index-icon"
                                                                            ]
                                                                        }
                                                                        src={
                                                                            this
                                                                                .state
                                                                                .rankIcon[
                                                                                index
                                                                            ]
                                                                        }
                                                                        alt="rank"
                                                                    />
                                                                )}
                                                                <span>
                                                                    {
                                                                        brand.brand_name
                                                                    }
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                $
                                                                {brand.grand_total_usd &&
                                                                    brand.grand_total_usd.toFixed(
                                                                        2
                                                                    )}
                                                            </p>
                                                            <p
                                                                className={
                                                                    style[
                                                                        "small-font"
                                                                    ]
                                                                }
                                                            >
                                                                占比:
                                                                {brand.grand_total_usd_ratio &&
                                                                    brand.grand_total_usd_ratio.toFixed(
                                                                        2
                                                                    )}
                                                                %&nbsp;&nbsp;环比:
                                                                <span
                                                                    className={
                                                                        brand.grand_total_usd_ring_ratio >
                                                                        0
                                                                            ? "heigh-light"
                                                                            : ""
                                                                    }
                                                                >
                                                                    {brand.grand_total_usd_ring_ratio &&
                                                                        brand.grand_total_usd_ring_ratio.toFixed(
                                                                            2
                                                                        )}
                                                                    %
                                                                </span>
                                                            </p>
                                                        </td>
                                                        {/* <td>
                        <p>{brand.goods_num && brand.goods_num}</p>
                        <p className={style['small-font']}>
                          环比:<span className={brand.goods_num_ring_ratio > 0 ? 'heigh-light' : ''}>{brand.goods_num_ring_ratio && brand.goods_num_ring_ratio.toFixed(2)}%</span>
                        </p>
                      </td> */}
                                                        <td>
                                                            <p>
                                                                {brand.ad_total_fee &&
                                                                    brand.ad_total_fee.toFixed(
                                                                        2
                                                                    )}
                                                                %
                                                            </p>
                                                            <p
                                                                className={
                                                                    style[
                                                                        "small-font"
                                                                    ]
                                                                }
                                                            >
                                                                ROI:
                                                                {brand.roi &&
                                                                    brand.roi.toFixed(
                                                                        2
                                                                    )}
                                                                <br />
                                                                连带:
                                                                {brand.associated_rate &&
                                                                    brand.associated_rate.toFixed(
                                                                        2
                                                                    )}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            $
                                                            {brand.unit_price &&
                                                                brand.unit_price.toFixed(
                                                                    2
                                                                )}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div
                                className={style["rank-container"]}
                                data-anchor="2"
                            >
                                <h4 className={style["rank-title"]}>
                                    优化师分析（日）
                                </h4>
                                <div className={style["rank-table-container"]}>
                                    <table className={style["rank-table"]}>
                                        <thead>
                                            <tr>
                                                <th>优化师</th>
                                                <th>成交额</th>
                                                <th>完成进度</th>
                                                <th>客单价</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.optimizerList.map(
                                                (optimizer, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <div
                                                                className={
                                                                    style[
                                                                        "rank-td"
                                                                    ]
                                                                }
                                                            >
                                                                {index >= 3 ? (
                                                                    <span
                                                                        className={
                                                                            style[
                                                                                "index-icon"
                                                                            ]
                                                                        }
                                                                    >
                                                                        {index +
                                                                            1}
                                                                    </span>
                                                                ) : (
                                                                    <img
                                                                        className={
                                                                            style[
                                                                                "index-icon"
                                                                            ]
                                                                        }
                                                                        src={
                                                                            this
                                                                                .state
                                                                                .rankIcon0[
                                                                                index
                                                                            ]
                                                                        }
                                                                        alt="rank"
                                                                    />
                                                                )}
                                                                <span
                                                                    style={{
                                                                        flex: 1,
                                                                    }}
                                                                >
                                                                    {
                                                                        optimizer.name
                                                                    }
                                                                </span>
                                                                <div
                                                                    className={
                                                                        optimizer.ranking_diff >
                                                                        0
                                                                            ? "heigh-light"
                                                                            : ""
                                                                    }
                                                                >
                                                                    <Icon
                                                                        style={{
                                                                            fontSize:
                                                                                16 /
                                                                                    75 +
                                                                                "rem",
                                                                        }}
                                                                        type={
                                                                            optimizer.ranking_diff >
                                                                            0
                                                                                ? "up"
                                                                                : "down"
                                                                        }
                                                                    />
                                                                    <span>
                                                                        {Math.abs(
                                                                            optimizer.ranking_diff
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                $
                                                                {optimizer.day_grand_total_usd &&
                                                                    optimizer.day_grand_total_usd.toFixed(
                                                                        2
                                                                    )}
                                                            </p>
                                                            <p
                                                                className={
                                                                    style[
                                                                        "small-font"
                                                                    ]
                                                                }
                                                            >
                                                                占比:
                                                                {optimizer.grand_total_usd_ratio &&
                                                                    optimizer.grand_total_usd_ratio.toFixed(
                                                                        2
                                                                    )}
                                                                %&nbsp;&nbsp;环比:
                                                                <span
                                                                    className={
                                                                        optimizer.day_usd_ring_rate >
                                                                        0
                                                                            ? "heigh-light"
                                                                            : ""
                                                                    }
                                                                >
                                                                    {optimizer.day_usd_ring_rate &&
                                                                        optimizer.day_usd_ring_rate.toFixed(
                                                                            2
                                                                        )}
                                                                    %
                                                                </span>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {optimizer.ad_total_fee &&
                                                                    optimizer.ad_total_fee.toFixed(
                                                                        2
                                                                    )}
                                                                %
                                                            </p>
                                                            <p
                                                                className={
                                                                    style[
                                                                        "small-font"
                                                                    ]
                                                                }
                                                            >
                                                                ROI:
                                                                {optimizer.roi &&
                                                                    optimizer.roi.toFixed(
                                                                        2
                                                                    )}
                                                                <br />
                                                                连带:
                                                                {optimizer.associated_rate &&
                                                                    optimizer.associated_rate.toFixed(
                                                                        2
                                                                    )}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            $
                                                            {optimizer.unit_price &&
                                                                optimizer.unit_price.toFixed(
                                                                    2
                                                                )}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div
                                className={style["table-container"]}
                                data-anchor="3"
                            >
                                <h3>优化师 TOP 5</h3>
                                <div className={style["optimization-table"]}>
                                    {this.state.optimizerTop.map(
                                        (optimizer, index) => (
                                            <div
                                                className={
                                                    style["optimization-item"]
                                                }
                                                key={index}
                                            >
                                                <div
                                                    className={
                                                        style[
                                                            "optimization-index"
                                                        ]
                                                    }
                                                >
                                                    {index + 1}
                                                </div>
                                                <div
                                                    className={
                                                        style[
                                                            "optimization-name"
                                                        ]
                                                    }
                                                >
                                                    {optimizer.name}
                                                </div>
                                                <div
                                                    className={
                                                        style[
                                                            "optimization-progress"
                                                        ]
                                                    }
                                                >
                                                    <p>
                                                        完成率：
                                                        {optimizer.completion_rate &&
                                                            optimizer.completion_rate.toFixed(
                                                                2
                                                            )}
                                                        %&nbsp;$
                                                        {optimizer.month_grand_total_usd &&
                                                            optimizer.month_grand_total_usd.toFixed(
                                                                2
                                                            )}
                                                        &nbsp;/&nbsp;$
                                                        {optimizer.month_target_grand_total_usd &&
                                                            optimizer.month_target_grand_total_usd.toFixed(
                                                                2
                                                            )}
                                                    </p>
                                                    <div
                                                        className={
                                                            style[
                                                                "progress-container"
                                                            ]
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                style[
                                                                    "progress"
                                                                ]
                                                            }
                                                            style={{
                                                                width:
                                                                    (optimizer.completion_rate >=
                                                                    100
                                                                        ? 100
                                                                        : optimizer.completion_rate) +
                                                                    "%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        style[
                                                            "optimization-roi"
                                                        ]
                                                    }
                                                >
                                                    <p>ROI</p>
                                                    <p>
                                                        {optimizer.roi &&
                                                            optimizer.roi.toFixed(
                                                                2
                                                            )}
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        style[
                                                            "optimization-today"
                                                        ]
                                                    }
                                                >
                                                    <p>今日</p>
                                                    <p>
                                                        $
                                                        {optimizer.day_grand_total_usd &&
                                                            optimizer.day_grand_total_usd.toFixed(
                                                                2
                                                            )}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        );
    }
}
export default MobileMain;
