/*
 * @Author: your name
 * @Date: 2021-08-26 09:15:07
 * @LastEditTime: 2021-08-26 15:06:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /bi-screen-mobile-h5/src/App.js
 */
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import MobileMain from "./page/main";
import ZMobileMain from "./page/zzmain";
import GLobileMain from "./page/glmain";
import ZRobileMain from "./page/zrmain";
import Login from "./page/login";
import Auth from "./page/auth";
function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Login />
                </Route>
                <Route
                    path={
                        new String(window.location.pathname).indexOf("gl") >
                        -1
                            ? "/glmain"
                            : new String(window.location.pathname).indexOf("zz") >
                        -1
                            ? "/zzmain"
                            : new String(window.location.pathname).indexOf("zr") >
                            -1 ? "/zrmain" : "/main"
                    }
                >
                    {new String(window.location.pathname).indexOf("gl") >
                    -1 ? (
                        <GLobileMain />
                    ) : new String(window.location.pathname).indexOf("zz") >
                    -1 ? (
                        <ZMobileMain />
                    ) :  new String(window.location.pathname).indexOf("zr") >
                    -1 ?  <ZRobileMain /> : (
                        <MobileMain />
                    )}
                </Route>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/auth">
                    <Auth />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
