import style from "./index.module.scss";
import React, { Component } from "react";

const ColorConfig = ["#968658", "#97862c", "#97750e", "#976d13"];

class Analycis extends Component {
    componentDidMount() {
        // var myChart = window.echarts.init(
        //     document.getElementById("pieChartContainer")
        // );
        // myChart.setOption({
        //     color: ColorConfig,
        //     series: [
        //         {
        //             type: "pie",
        //             hoverAnimation: false,
        //             radius: "90%",
        //             center: ["50%", "50%"],
        //             data: [],
        //             label: {
        //                 show: false,
        //             },
        //             itemStyle: {
        //                 normal: {
        //                     borderWidth: 2,
        //                     borderColor: "#1c1c1e",
        //                 },
        //             },
        //         },
        //     ],
        // });
        // this.myChart = myChart;
    }

    componentDidUpdate() {
        // if (this.myChart) {
        //     const PieData = this.props.state.refunds.map((item) => {
        //         return {
        //             value: item.refund_amount_usd
        //                 ? parseInt(item.refund_amount_usd.toFixed(2))
        //                 : 0,
        //             name: item.pay_month
        //                 ? new Date(
        //                       item.pay_month.replace(/-/g, "/")
        //                   ).getMonth() +
        //                   1 +
        //                   "月支付"
        //                 : "其他月支付",
        //         };
        //     });
        //     this.myChart.setOption({
        //         series: {
        //             data: PieData,
        //         },
        //     });
        // }
    }

    render() {
        const { stocks, refunds, totalRefund, spus } = this.props.state;
        return (
            <div className={style["analycis"]} data-anchor="7">
                {/* <h3>
                    {new Date(
                        this.props.state.date.replace(/-/g, "/")
                    ).getMonth() + 1}
                    月退款情况
                </h3> */}
                {/* <AnalycisBar>
          <AnalycisHeader head={'退款金额：$' + totalRefund.toFixed(2)}></AnalycisHeader>
          <div className={style['pie-panel']}>
            <div id="pieChartContainer" className={style['pie-container']}></div>
            <div className={style['pie-table']}>
              <table>
                <tbody>
                  <tr>
                    <td>订单成交月份</td>
                    <td>本月退款</td>
                    <td>成交额</td>
                    <td>退款率</td>
                  </tr>
                  {refunds.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className={style['month']}>
                          <div className={style['month-icon']} style={{background: ColorConfig[index]}}></div>
                          <span>{item.pay_month ? (new Date(item.pay_month.replace(/-/g, '/')).getMonth() + 1 + '月') : '往月'}</span>
                        </div>
                      </td>
                      <td>{item.refund_amount_usd && ('$' + item.refund_amount_usd.toFixed(2))}</td>
                      <td>{item.grand_total_usd && ('$' + item.grand_total_usd.toFixed(2))}</td>
                      <td>{item.refund_rate && (item.refund_rate.toFixed(2) + '%')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </AnalycisBar> */}
                <h3
                    data-anchor="6"
                    style={{
                        fontSize: "0.34667rem",
                        fontWeight: "bold",
                        color: "#bababa",
                        textAlign: "center",
                    }}
                >
                    缺货情况分析
                </h3>
                <AnalycisBar>
                    {spus.map((item, index) => (
                        <AnalycisOutOfStockBar stock={item} key={index} />
                    ))}
                </AnalycisBar>
                <AnalycisBar>
                    {stocks.map((item, index) => (
                        <AnalycisOutOfStockBar
                            stock={item}
                            key={index}
                        ></AnalycisOutOfStockBar>
                    ))}
                </AnalycisBar>
            </div>
        );
    }
}

const AnalycisBar = function (props) {
    return <div className={style["analycis-view"]}>{props.children}</div>;
};

const AnalycisHeader = function (props) {
    return (
        <div className={style["analycis-header"]}>
            <div className={style["analycis-title"]}>{props.head}</div>
            {props.children}
        </div>
    );
};

const AnalycisOutOfStockBar = function (props) {
    const { goods_type, stock_rate, stock_num, order_num } = props.stock;
    return (
        <div className={style["out-of-stock-bar"]}>
            <div className={style["out-of-stock-sub-info"]}>
                <span>缺货率：{stock_rate && stock_rate.toFixed(2)}%</span>
                <span>
                    {stock_num}/{order_num}
                </span>
                {props.children}
            </div>
            <div className={style["out-of-stock-bar-view"]}>
                <div className={style["out-of-stock-bar-info"]}>
                    {goods_type}
                </div>
                <div className={style["out-of-stock-outside-bar"]}>
                    <div
                        className={style["out-of-stock-inside-bar"]}
                        style={{ width: stock_rate + "%" }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default Analycis;
