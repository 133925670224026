/*
 * @Author: your name
 * @Date: 2021-08-26 09:15:07
 * @LastEditTime: 2021-08-26 15:03:57
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /bi-screen-mobile-h5/src/page/auth/index.js
 */
import React, { useState, useEffect } from "react";
import { Result, Icon } from "antd-mobile";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { login } from "../../service";

function Auth(props) {
    const [code] = useState(
        new URLSearchParams(useLocation().search).get("code")
    );
    const [loginStatus, setLoginStatus] = useState("loading");

    useEffect(() => {
        const loginFunc = async () => {
            if (code) {
                let result = await login(code);
                if (result.status === 200) {
                    setLoginStatus("success");
                    Cookies.set("token", result.data.token);
                    window.location.replace(
                        new String(window.location.pathname).indexOf("gl") >
                            -1
                            ? "/glmain" : new String(window.location.pathname).indexOf("zz") >
                            -1
                            ? "/zzmain" : new String(window.location.pathname).indexOf("zr") >
                            -1
                            ?  "/zrmain"
                            : "/main"
                    );
                } else {
                    setLoginStatus("fail");
                }
            } else {
                setLoginStatus("fail");
            }
        };
        loginFunc();
    }, [code]);

    return loginStatus === "loading" ? (
        <Result
            img={
                <Icon
                    type="loading"
                    style={{ fill: "#1f90e6", width: 60, height: 60 }}
                />
            }
            title="登录中"
            message="授权登录中，请稍等..."
        />
    ) : loginStatus === "fail" ? (
        <Result
            img={
                <Icon
                    type="cross-circle-o"
                    style={{ fill: "#f13642", width: 60, height: 60 }}
                />
            }
            title="登录失败"
            message="企业微信权限认证失败"
        />
    ) : loginStatus === "success" ? (
        <Result
            img={
                <Icon
                    type="check-circle"
                    style={{ fill: "#2fd7aa", width: 60, height: 60 }}
                />
            }
            title="登陆成功"
            message="自动跳转中..."
        />
    ) : null;
}

export default Auth;
