/*
 * @Author: your name
 * @Date: 2021-08-26 09:15:07
 * @LastEditTime: 2024-01-18 15:52:26
 * @LastEditors: M.re undefined
 * @Description: In User Settings Edit
 * @FilePath: /bi-screen-mobile-h5/src/service/index.js
 */
import Cookies from "js-cookie";
const axios = require("axios");
const host = process.env.REACT_APP_API_URL || "";
const ua = navigator.userAgent.toLowerCase();
// 100 创乐， 101 MW 102 RL
let affix = '/457072'
if(new String(window.location.pathname).indexOf('zr') > -1) {
  affix =  '/102'
} else if (new String(window.location.pathname).indexOf('zz') > -1) {
  affix =  '/101'
} else if (new String(window.location.pathname).indexOf('gl') > -1) {
    affix =  '/104'
  }
var request = axios.create({
    baseURL: host,
    timeout: 20000,
    headers: {
        AuthorizationMobile: Cookies.get("token"),
        'client-device': ua.indexOf('wxwork') !== -1 ? 'wapWechar' : 'wap-other',
    },
});
request.interceptors.response.use(
    (response) => {
        console.log(response)
        if (response.data.status === 403 || response.data.status === 10001) {
            Cookies.remove("token");
            // window.location.replace("/login");
        }
        return response.data;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 登录
export async function login(code) {
    return request.post("/api/v1/screen/login", {
        code,
    });
}
// 首页数据
export async function screenInfo(date) {
    return request.get(`/api/v1/screen/${date}/info${affix}`);
}
// kpi列表
export async function listinfo(date) {
    return request.get(`/api/v1/screen/${date}/listinfo${affix}`);
}

// 供应大屏
export async function supplyinfo(date) {
    return request.get(`/api/v1/screen/supply/${date}/info`);
}

// 售后大屏
export async function afterSale({ date }) {
    return request(`${host}/api/v1/screen/application/${date}/info`);
}
